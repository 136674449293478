<template>
  <div ref="carousel" class="carousel" :style="`flex-direction:${directional=='left'?'row':'row-reverse'};margin-${directional}:${margin}px;`">
    <div v-for="(item,index) in list" :key="index">
      <slot :value="item"></slot>
    </div>
  </div>

</template>

<script>
export default {
  name: '',
  components: {},
  props: {
    directional: {
      default: 'left',
      type: String
    },
    list: {
      default: () => {
        return []
      },
      type: Array
    }
  },
  data () {
    return {
      margin: 0
    }
  },
  methods: {
    play () {
      let div = this.$refs.carousel.querySelector('div')
      if (div) {
        let width = div.scrollWidth
        this.interval = setInterval(() => {
          if (this.margin == 0) {
            this.list.push(this.list[0])
          }
          this.margin--
          if (this.margin <= -(width - 1)) {
            this.margin = 0
            this.list.splice(0, 1)[0]
          }
        }, 15)
      }
    },
    stop () {
      clearInterval(this.interval)
    }
  },
  mounted () {
    this.play()
  }
}
</script>

<style lang="scss" scoped>
.carousel {
  width: 100%;
  margin: 0 auto;
  white-space: nowrap;
  text-align: right;
  display: flex;
}
</style>