<template>
  <div>
    <Header />
    <div>
      <!-- banner -->
      <Banner>
        <img class="banner-bg" style="width:auto;height:100%" src="@/assets/image/home/home_banner.png" alt="首页">
        <template v-slot:text>
          <div class="slogan"><span>“豸”</span>为公平正义 <span>“信”</span>因明辨真伪</div>
          <div class="label">豸信CA，国内领先的电子认证服务和信息安全解决方案提供商</div>
          <el-button @click="$bus.$emit('cgContactFormShow', true)" type="primary">立即咨询</el-button>
        </template>
      </Banner>
      <!-- 问题介绍 -->
      <div class="introduce main-box">
        <div>
          <img src="@/assets/icons/home/introduce-icon1.png" alt="是什么" srcset="">
          <div>
            <div class="tt">豸信CA是什么？</div>
            <div>提供基于云计算的安全技术研发、<br>集成以及信息安全服务</div>
          </div>
        </div>
        <div>
          <img src="@/assets/icons/home/introduce-icon2.png" alt="为什么" srcset="">
          <div>
            <div class="tt">为什么选择豸信CA？</div>
            <div>拥有覆盖全国的电子认证服务网络<br>和较完善的电子认证产品体系</div>
          </div>
        </div>
        <div>
          <img src="@/assets/icons/home/introduce-icon3.png" alt="应用领域" srcset="">
          <div>
            <div class="tt">我们的应用领域？</div>
            <div>覆盖政务、医疗、教育、企业等多<br>个领域电子认证服务</div>
          </div>
        </div>
        <div>
          <img src="@/assets/icons/home/introduce-icon4.png" alt="电子签署" srcset="">
          <div>
            <div class="tt">专业可信的电子签署服务</div>
            <div>一站式解决电子签署难题，合规合法<br>安全高效</div>
          </div>
        </div>
      </div>
      <!-- 产品介绍 -->
      <div class="module-title">
        产品介绍
        <div class="hint">
          根据您不同场景的业务需求提供相应的产品
        </div>
      </div>
      <div class="product">
        <div class="tab-butns main-box">
          <div v-for="(item,index) in product" :key="index" @mouseenter="productActive=index" :class="{'active':index==productActive}" class="icon-animation-box">
            <div class="icon-animation icon" :style="{'background-image': `url(${require('@/assets/icons/home/product-icon'+(index+1)+'.png')})`}"></div>
            <div>{{item.name}}</div>
            <div class="bottom"></div>
          </div>
        </div>
        <div class="tabs">
          <div class="main-box">
            <div class="text">
              <div class="tt">{{product[productActive].name}}</div>
              <div>{{product[productActive].intro}}</div>
            </div>
            <div v-for="(item,index) in product[productActive].examples" :key="index" class="example">
              <img class="bg" src="@/assets/image/home/product-hover-bg.png" alt="悬浮icon">
              <div class="tt">{{item.name}}</div>
              <div class="explain">{{item.intro}}</div>
              <el-button @click="$router.push({name:item.path})" type="primary" size="small">查看详情</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 服务与支持 -->
      <div class="module-title">
        服务与支持
        <div class="hint">
          全方位证书生命周期服务，让您的数字证书更加安全便捷
          <router-link :to="{name:'serviceHall'}">前往服务大厅<i class="el-icon-arrow-right"></i></router-link>
        </div>
      </div>
      <div class="serve main-box">
        <div class="left">
          <img src="@/assets/icons/home/serve-bg.png" alt="服务与支持">
        </div>
        <div class="right">
          <div class="icon-animation-box" v-for="(item,index) in serves" @click="goRouter(item.name)" :key="index">
            <div class="icon-animation icon" :style="{'background-image': `url(${require('@/assets/icons/home/serve-icon-'+(item.name)+'.png')})`}"></div>
            <div>{{item.label}}</div>
          </div>
        </div>
      </div>
      <!-- 我们的客户与合作伙伴 -->
      <div class="module-title">
        我们的客户与合作伙伴
      </div>
      <div class="client main-box">
        <carousel :list="[1,2,3,4,5,6,7,8]">
          <template v-slot:default="slot">
            <div class="carousel-item">
              <img :src="require(`@/assets/icons/home/client/client-logo${slot.value}.png`)" alt="客户与合作伙伴">
            </div>
          </template>
        </carousel>
        <carousel directional="right" :list="[9,10,11,12,13,14,15]">
          <template v-slot:default="slot">
            <div class="carousel-item">
              <img :src="require(`@/assets/icons/home/client/client-logo${slot.value}.png`)" alt="客户与合作伙伴">
            </div>
          </template>
        </carousel>
        <carousel :list="[16,17,18,19,20,21,22,23,24]">
          <template v-slot:default="slot">
            <div class="carousel-item">
              <img :src="require(`@/assets/icons/home/client/client-logo${slot.value}.png`)" alt="客户与合作伙伴">
            </div>
          </template>
        </carousel>
      </div>
      <div class="affirmance" v-if="bigNotice">
        <div class="changeAnnouncement">
          <h2>关于发票业务暂停公告<i class="el-icon-close" @click="closeNotice"></i></h2>
          <h3>尊敬的各位合作伙伴和豸信ca用户:</h3>
          <p>因豸信ca公司管辖地税务机关推行数电票切换工作，于2024年3月20日至2024年3月30日期间暂停自助开票业务，如有开票需求，请4月1日后自助办理!不便之处，敬请谅解!</p>
          <p> <br /></p>
          <p> <br /></p>
          <p> <br /></p>
          <p> <br /></p>
          <p> <br /></p>

          <!-- <p>公司更名后不影响公司原有的各项权利和义务，各项业务关系均转到“山东豸信认证服务有限公司”，数字证书业务不受影响，更新之后的证书为豸信CA颁发。</p>
        <p>即日起，公司相关资料、开票信息及用章等全部使用新公司名称，原相关合作协议及合同等合约转为新公司同等生效。</p>
        <p>特此通知！</p>
        <p>由此给您带来不便，敬请谅解！祝商祺！</p> -->
          <h4>山东豸信认证服务有限公司</h4>
          <h5>2024年03月20日</h5>
          <button @click="closeNotice">我知道了</button>
        </div>
      </div>
      <div class="suspensionNotice">
        <div class="streamerNotice">
          关于“山东豸信认证服务有限公司”发票业务暂停通知。
          <span @click="closeNotice">查看详情<i class="el-icon-arrow-right"></i></span>
        </div>
      </div>
    </div>
    <Footer />
    <Sidebar />
  </div>
</template>

<script>
import carousel from './carousel.vue'
import Header from './../components/Header.vue'
import Footer from './../components/Footer.vue'
import Sidebar from './../components/Sidebar.vue'
export default {
  components: { carousel, Header, Footer, Sidebar },
  data() {
    return {
      product: [
        {
          name: '数字证书',
          intro:
            '豸信CA作为独立、公正、可信的CA机构，严格按照《中华人民共和国电子签名法》、《电子认证服务管理办法》等相关法律要求为各类用户颁发数字证书，并保障数字证书的安全使用。豸信CA可颁发机构证书、个人证书以及国密SSL证书等各类证书并广泛应用于政府、医院、高校、企业等各个领域。',
          examples: [
            {
              path: 'product-orgCert',
              name: '机构证书',
              intro: '用于标识证书持有机构在网络上的真实身份'
            },
            {
              path: 'product-personCert',
              name: '个人证书',
              intro: '用以标识个人在互联网上的数字身份'
            }
          ]
        },
        {
          name: '电子签章',
          intro:
            '电子签章利用图像处理技术将电子签名操作转化为与纸质文件盖章操作相同的可视效果，同时利用电子签名技术保障电子信息的真实性和完整性以及签名人的不可否认性。豸信CA提供多种电子签章产品适配不同客户的电子签章诉求。',
          examples: [
            {
              path: 'product-sealBox',
              name: '智能印章一体机',
              intro: '软硬件融合实现企业电子印章的智能化管理和安全性管理。'
            },
            {
              path: 'product-eSeal',
              name: '电子签章系统',
              intro: '将传统印章图片与可靠的电子签名技术完美结合'
            }
          ]
        },
        {
          name: '数据安全与密码服务',
          intro:
            '密码技术是保障网络空间安全的核心技术和基础支撑，是实现数据安全和网络安全的重要途径。豸信CA致力于使用国产密码技术，有效保证个人信息安全和企业商业机密在数据产生、传递、存储中的机密性、完整性以及不可否认性。',
          examples: [
            {
              path: 'product-verifySign',
              name: '签名验签',
              intro: '有效地保障业务数据的真实性和有效性'
            },
            {
              path: 'product-timestamp',
              name: '可信时间戳',
              intro: '证明电子数据的完整性及产生时间'
            }
          ]
        },
        {
          name: '移动端数字密码服务',
          intro:
            '手机盾是面向移动端场景的一款产品,提供可信的数字签名功能。协同签名系统采用密钥分割技术和协同数字签名技术,实现了密钥分量在移动签名组件和服务器端的独立生成和独立存储。签名过程中,移动端和服务端协作,共同完成完整签名。可适用于OA协同、电子政务、移动办公、金融支付、健康医疗、移动端身份认证等多种场景。',
          examples: [
            {
              path: 'product-mobileShield',
              name: '手机盾',
              intro: '移动端的数字密码应用服务'
            }
          ]
        }
      ],
      productActive: 0,
      serves: [
        { label: '证书申请', name: 'certApply' },
        { label: '证书续期', name: 'certRenewal' },
        { label: '证书解锁', name: 'certUnlock' },
        { label: '证书注销', name: 'certCancel' },
        { label: '证书查询', name: 'certQuery' },
        { label: '文档验证', name: 'fileVerify' }
      ],
      bigNotice: false
    }
  },
  mounted() {},
  methods: {
    verify(name) {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => {
        this.verify(name)
      })
      this.$api.getAccountInfo().then(res => {
        if (res.data.verified) {
          let routeData = this.$router.resolve({ name })
          window.open(routeData.href, '_blank')
        } else {
          this.$confirm(
            '当前账号未完成管理员实名认证，实名之后才可办理业务。',
            '提示',
            {
              confirmButtonText: '实名认证',
              cancelButtonText: '暂不认证',
              type: 'warning'
            }
          )
            .then(() => {
              this.$router.push({ path: '/personHome' })
            })
            .catch(() => {})
        }
      })
    },
    goRouter(name) {
      if (['certQuery', 'fileVerify'].includes(name)) {
        // this.$router.push({ name })
        let routeData = this.$router.resolve({ name })
        window.open(routeData.href, '_blank')
      } else {
        this.verify(name)
      }
    },
    closeNotice() {
      this.bigNotice = !this.bigNotice
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep.banner-box {
  .banner-bg-box {
    width: 100%;
    max-width: 100%;
    .banner-bg {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
      min-width: 100%;
    }
  }

  .text {
    .slogan {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 57px;
      span {
        font-size: 48px;
        color: $primaryColor;
        margin: 0 24px;
      }
    }
    .label {
      font-size: 18px;
      color: #303b50;
      line-height: 24px;
      letter-spacing: 1px;
      padding: 0 32px;
      position: relative;
      display: inline-block;
      margin-bottom: 72px;
      // &::after,
      // &::before {
      //   content: '';
      //   width: 112px;
      //   height: 1px;
      //   background: #cfdae9;
      //   position: absolute;
      //   left: 0;
      //   top: 12px;
      // }
      // &::after {
      //   left: auto;
      //   right: 0;
      // }
    }
    .el-button {
      display: block;
      margin-left: 32px;
      margin-top: 0;
    }
  }
}
.introduce {
  display: flex;
  margin-top: -52px;
  z-index: 9;
  position: relative;
  & > div {
    height: 114px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px rgba(31, 37, 81, 0.11);
    border-radius: 4px;
    margin-right: 23px;
    display: flex;
    font-size: 12px;
    padding-left: 24px;
    color: #8d96a3;
    line-height: 22px;
    align-items: center;
    width: 100%;
    .tt {
      font-size: 16px;
      color: #303b50;
      line-height: 21px;
      margin-bottom: 4px;
      font-weight: 700;
    }
    img {
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }
    &:last-child {
      margin: 0;
    }
  }
}
.module-title {
  margin-top: 160px;
  margin-bottom: 64px;
  font-size: 36px;
  color: #303b50;
  line-height: 48px;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 700;
  .hint {
    font-size: 16px;
    color: #8d96a3;
    line-height: 21px;
    margin-top: 20px;
    padding-bottom: 16px;
    font-weight: normal;
  }
}
.product {
  .tab-butns {
    display: flex;
    justify-content: space-between;
    text-align: center;
    font-size: 18px;
    color: #303b50;
    line-height: 24px;
    & > div {
      transition: all 0.2;
      cursor: pointer;
      &.active {
        .bottom {
          opacity: 1;
        }
      }
      &:hover {
        color: $primaryColor;
      }
    }

    .icon {
      margin: 0 auto;
      margin-bottom: 12px;
    }
    .bottom {
      opacity: 0;
      width: 75px;
      height: 2px;
      background: $primaryColor;
      position: relative;
      margin: 0 auto;
      margin-top: 19px;
      transition: all 0.2;
      &::after {
        content: '';
        position: absolute;
        border-top: 9px $primaryColor solid;
        border-left: 9px transparent solid;
        border-right: 9px transparent solid;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .tabs {
    width: 100%;
    background: #f9fbff;
    .main-box {
      padding: 80px 40px 58px 40px;
      display: flex;
      .text {
        padding-top: 7px;
        font-size: 16px;
        color: #303b50;
        line-height: 32px;
        margin-right: 24px;
        .tt {
          font-size: 24px;
          color: #303b50;
          line-height: 32px;
          margin-bottom: 42px;
          font-weight: 700;
        }
      }
      .example {
        width: 222px;
        height: 294px;
        background: linear-gradient(124deg, #eceef9 0%, #ffffff 100%) 100%;
        box-shadow: 0px 4px 10px 0px rgba(31, 37, 81, 0.11);
        border-radius: 4px;
        border: 2px solid #ffffff;
        flex-shrink: 0;
        margin-left: 24px;
        padding: 46px 32px;
        text-align: center;
        position: relative;
        .bg {
          position: absolute;
          width: 140px;
          height: 166px;
          right: 0;
          top: 22px;
          display: none;
        }
        &:hover {
          .bg {
            display: block;
          }
        }
        .tt {
          font-size: 21px;
          color: #303b50;
          line-height: 28px;
          margin-bottom: 16px;
          text-align: left;
          font-weight: 700;
        }
        .explain {
          font-size: 16px;
          color: #666e7a;
          line-height: 26px;
          height: 127px;
          text-align: left;
        }
        .el-button {
          margin: 0 auto;
          width: 102px;
        }
      }
    }
  }
}
.serve {
  display: flex;
  position: relative;
  height: 487px;
  .left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 534px;
    height: 403px;
    background: linear-gradient(92deg, #8197f4 0%, #0059ff 100%);
    border-radius: 16px;
    img {
      width: 360px;
      height: 287px;
      position: absolute;
      left: 48px;
      top: 57px;
    }
  }
  .right {
    width: 864px;
    height: 487px;
    background: rgba(238, 245, 255, 0.3);
    box-shadow: 0px 6px 10px 0px #f0f4fc,
      6px 0px 10px 0px rgba(240, 244, 252, 0.11);
    border-radius: 16px;
    border: 2px solid #dbe4ff;
    backdrop-filter: blur(12px);
    right: 0;
    top: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    padding: 0 50px;
    & > div {
      width: 145px;
      height: 145px;
      background: linear-gradient(180deg, #eff1fa 0%, #ffffff 100%);
      box-shadow: 0px 4px 10px 0px rgba(31, 37, 81, 0.11);
      border-radius: 4px;
      border: 2px solid #ffffff;
      font-size: 16px;
      color: #303b50;
      line-height: 21px;
      text-align: center;
      padding-top: 14px;
      margin: 32px;
      cursor: pointer;
      &:hover {
        color: $primaryColor;
      }
      .icon {
        width: 72px;
        height: 72px;
        margin: 0 auto;
        margin-bottom: 10px;
      }
    }
  }
}
.client {
  overflow-x: hidden;
  margin-bottom: 160px;
  .carousel-item {
    width: 241px;
    height: 144px;
    background: #fdfdff;
    box-shadow: 0px 4px 8px 0px rgba(31, 37, 81, 0.11);
    border-radius: 4px;
    margin: 12px;
    text-align: center;
    flex-shrink: 0;
    // padding: 30px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      // max-width: 60%;
      // position: absolute;
      // max-height: 60%;
      // left: 50%;
      // top: 50%;
      // transform: translate(-50%, -50%);
    }
  }
}
.affirmance {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99;
}
.changeAnnouncement {
  width: 772px;
  height: 506px;
  background: #fdfeff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px 24px 24px 32px;
  box-sizing: border-box;
  background-color: #fdfeff;
  background-image: url('~@/assets/image/home/drawing1.png'),
    url('~@/assets/image/home/drawing2.png'),
    url('~@/assets/image/home/drawing3.png');
  background-position: 0 354px, 699px 127px, 31px 91px;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 178px, 50px, 50px;
  h2 {
    font-size: 18px;
    color: #333333;
    line-height: 24px;
    font-weight: 700;
  }
  i {
    float: right;
    color: #aeb5be;
    font-size: 14px;
    cursor: pointer;
  }
  h3 {
    font-size: 14px;
    color: #333333;
    line-height: 19px;
    padding-top: 24px;
    padding-bottom: 10px;
    font-weight: normal;
  }
  p {
    font-size: 14px;
    color: #333333;
    line-height: 26px;
    padding-right: 58px;
    text-indent: 2em;
    padding-bottom: 8px;
  }
  span {
    color: #4873f6;
  }
  h4 {
    text-align: right;
    padding-top: 7px;
    font-size: 14px;
    color: #333333;
    line-height: 19px;
    padding-right: 68px;
    font-weight: normal;
  }
  img {
    height: 16px;
  }
  h5 {
    text-align: right;
    font-size: 14px;
    color: #333333;
    line-height: 19px;
    padding-top: 10px;
    padding-right: 68px;
    font-weight: normal;
  }
  button {
    width: 116px;
    height: 42px;
    background: #4873f6;
    border-radius: 31px;
    float: right;
    border: none;
    font-size: 16px;
    color: #ffffff;
    line-height: 21px;
    margin-top: 37px;
  }
}
.suspensionNotice {
  width: 100%;
  height: 42px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  background: #ffffff;
  display: none;
}
.streamerNotice {
  width: 1300px;
  margin: 0 auto;
  background: url('~@/assets/image/home/notice.png') 40px 10px no-repeat;
  background-size: 74px;
  padding-left: 133px;
  padding-right: 40px;
  box-sizing: border-box;
  line-height: 42px;
  color: #333333;
  cursor: default;
  span {
    // float: right;
    display: inline-block;
    padding-left: 20px;
    color: #1c67f3;
    cursor: pointer;
  }
}
</style>